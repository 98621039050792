<template>
  <div>
    <v-text-field
      id="search-place-field"
      ref="searchPlacesField"
      type="text"
      v-model="autocompleteQuery"
      :placeholder="$t('general.pin.placeholder')"
      outlined
      hide-details
      :readonly="readOnly"
    />
    <div
      class="wrapper--suggest"
      v-show="autocompleteSuggestions && autocompleteSuggestions.length > 0"
    >
      <place-suggestion
        v-for="suggestion in autocompleteSuggestions"
        :key="suggestion.id"
        :suggestion="suggestion"
        @suggestionClicked="suggestionClicked"
        :hideSuggestion="hideSuggestion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LodashMixin from '@/mixins/lodash';
const PlaceSuggestion = () =>
  import('@/components/data-entry/listing/form/map/place-sugestion.vue');
export default {
  name: 'search-places',
  mixins: [LodashMixin],
  props: ['readOnly'],
  components: { PlaceSuggestion },
  data() {
    return {
      hideSuggestion: false,
      autocompleteService: null,
      sessionToken: null,
      autocompleteQuery: '',
      autocompleteSuggestions: [],
      shouldSearch: true,
    };
  },
  created() {
    // eslint-disable-next-line no-undef
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // eslint-disable-next-line no-undef
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.debounceGetSuggestion = this.debounce(this.getSuggestion, 500);
  },
  computed: {
    ...mapGetters({
      // fetchingEditListingData:'listingForm/fetchingEditListingData',
      // loadPropertyDataFromList:'listingForm/property/loadPropertyDataFromList',
    }),
    loadingMap: {
      get() {
        return this.$store.state.listing.form.loadingMap;
      },
      set(value) {
        this.$store.commit('listing/form/SET_LOADING_MAP', value);
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    autocompleteQuery(value) {
      if (this.shouldSearch) {
        this.debounceGetSuggestion();
      } else {
        this.shouldSearch = true;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.windowClickListener);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickListener);
  },
  methods: {
    windowClickListener(e) {
      if (!document.querySelector('.wrapper--suggest').contains(e.target)) {
        this.hideSuggestion = !this.$refs.searchPlacesField.$el.contains(e.target);
      }
    },
    async getPlaceDetail(placeId) {
      this.loadingMap = true;
      try {
        const { data } = await this.$http.get('/api/map/get_place_detail', {
          params: {
            placeId,
          },
        });
        if (data.data) {
          const lat = data.data.location.lat;
          const lng = data.data.location.lng;
          this.$store.commit('listing/form/SET_LAT', lat);
          this.$store.commit('listing/form/SET_LNG', lng);
          this.$emit('placeDetailFound');
        }
      } finally {
        this.loadingMap = false;
      }
    },
    suggestionClicked(suggestion) {
      this.autocompleteSuggestions = [];
      this.shouldSearch = false;
      this.autocompleteQuery = suggestion.description;
      this.getPlaceDetail(suggestion.place_id);
    },
    getSuggestion() {
      if (this.autocompleteQuery) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.autocompleteQuery,
            sessionToken: this.sessionToken,
            componentRestrictions: { country: 'id' },
          },
          this.displaySuggestions,
        );
      } else {
        this.autocompleteSuggestions = [];
      }
    },
    displaySuggestions(predictions, status) {
      if (status != window.google.maps.places.PlacesServiceStatus.OK) {
        this.autocompleteSuggestions = [];
        return;
      }
      this.autocompleteSuggestions = predictions;
    },
  },
};
</script>

<style scoped></style>
